<template>
<div classs="bg-white">
  <div v-if="loading" class="text-white text-center">
    <transition name="fade">
      <div class="text-white text-center" :style="style">
        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top justify-content-between navbar-top" style="height: 80px;">
          <img :src="$config.BASE_URL + '/lg.jpg'" width="107.5" height="43" />
        </nav>
        <h1 class="kstyle title justify-content-md-center" style="margin-top: 100px;">Loading Tax Dashboard</h1>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 6em; margin-top: 100px;"></i>
        <div style="font-size: 1.5em; margin-top: 100px;">loaded: {{count}} initiatives</div>
      </div>
    </transition>
  </div>
  <b-container fluid v-if="!loading" classs="bg-white" style="margin: 0px; padding: 0px;">
      <b-row style="background: #1E49E2; padding: 0px; height: 57px;">
        <b-col style="background: #1E49E2; height: 57px;">
          <!-- <div style="background: #1E49E2; font-size: 16px; color: white; height: 57px;"> -->
          <b-navbar style="background: #1E49E2; height: 57px;">
            <b-navbar-nav>
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item :to="{ name: 'Tax' }" exact exact-active-class="active">Home</b-nav-item>
                <b-nav-item :to="{ name: 'TaxMap' }" exact exact-active-class="active">Map</b-nav-item>
                <b-nav-item :to="{ name: 'TaxCountry', params: { country: country } }" exact exact-active-class="active">Market</b-nav-item>
                <b-nav-item :to="{ name: 'TaxRegulations' }" exact exact-active-class="active">All records</b-nav-item>
                <b-nav-item :to="{ name: 'TaxNews' }" exact exact-active-class="active">News</b-nav-item>
                <b-nav-item :to="{ name: 'TaxInsights' }" exact exact-active-class="active">Insights</b-nav-item>
                <b-nav-item v-if="activeSingleInitiative" :active="activeSingleInitiative">Single initiative</b-nav-item>
                <b-nav-item v-if="$config.BUILD === 'full'" :to="{ name: 'TaxDataStrats' }" exact exact-active-class="active">Data stratification</b-nav-item>
                <b-nav-item v-if="permissions.add" :to="{ name: 'FormNew', params: { model: 'impactinitiative-tax' }}" exact exact-active-class="active">New initiative</b-nav-item>
              </b-nav>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item v-if="loadingBackground">Reloading {{ count }} records</b-nav-item>
                <b-nav-item @click="reload" v-b-popover.hover.top="'Reload data'">Reload <b-icon-arrow-clockwise v-if="!loadingBackground"></b-icon-arrow-clockwise><b-icon-arrow-clockwise animation="spin" v-if="loadingBackground"></b-icon-arrow-clockwise></b-nav-item>
                <b-nav-item >{{ initiativesTax.length}} records loaded</b-nav-item>
              </b-nav>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="ready">
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import PouchDB from 'pouchdb'

import ac from '@/libs/accesscontrol'
import workflow from '@/workflows/initiative.js'

import { BIconArrowClockwise } from 'bootstrap-vue'

let dbInitiativesTax = new PouchDB('initiativesTax')

export default {
  name: 'TaxLayout',
  components: {
    BIconArrowClockwise
  },
  computed: {
    activeSingleInitiative: function () {
      let result = false
      if (this.$route.name === 'TaxInitiative') {
        result = true
      }
      return result
    },
    country: function () {
      let result = ''
      if (this.$route.params.country) {
        result = this.$route.params.country
      } else if (window.localStorage.getItem('frmCloudCountrySelected')) {
        result = window.localStorage.getItem('frmCloudCountrySelected')
      } else {
        result = 'Australia'
      }
      return result
    },
    initiativesTax: {
      get () {
        return this.$store.state.initiativesTax
      },
      set (payload) {
        this.$store.commit('setInitiativesTax', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "ESG Tax Dashboard"
    this.$stat.log({ page: 'tax', action: 'open tax' })
    this.$store.commit('setBrand', 'ESG Tax Dashboard')
    const image = _.sample(['fox', 'penguin', 'photographer', 'tent', 'wave', 'whale'])
    this.style = `background-image: url('/img/${image}.jpg'); background-repeat: no-repeat; background-size: cover; position: fixed; z-index: 10000; top: 66px; right: 0px; bottom: 0px; left: 0px;`
    if (this.$config.BUILD === 'external') {
      this.permissions.add = ac.can(this.user.acgroups).readAny('initiative').granted
    } else {
      this.permissions.add = true
    }
    await this.load()
  },
  data () {
    const data = {
      count: 0,
      loading: true,
      loadingBackground: false,
      permissions: {
        add: false
      },
      ready: false,
      style: ''
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.ready = false
      let loadFromDb = false
      let reloadFromDb = false
      const infoDbInitiativesTax = await dbInitiativesTax.info()
      if (this.initiativesTax.length === 0) {
        if (infoDbInitiativesTax.doc_count === 0) {
          loadFromDb = true
        } else {
          let initiativesTax = await dbInitiativesTax.allDocs({ include_docs: true })
          initiativesTax = initiativesTax.rows.map(x => x.doc)
          this.count = initiativesTax.length
          this.initiativesTax = initiativesTax
          reloadFromDb = true
        }
      }
      if (loadFromDb) {
        await Promise.all([this.loadRows()])
        this.loading = false
        this.ready = true
      } else if (reloadFromDb) {
        this.loading = false
        this.ready = true
        this.loadingBackground = true
        await Promise.all([this.loadRows()])
        this.ready = false
        this.loadingBackground = false
        this.ready = true
      } else {
        this.loading = false
        this.ready = true
      }
    },
    loadRows: async function () {
      let rows = []
      try {
        const limit = 50
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/impact/initiatives-tax/limit/${limit}/offset/${offset}`)
          response.forEach(x => {
            x.workflow = workflow.find((element) => element.id === x.workflow_id).name
          })
          const data = rows.concat(response)
          rows = data
          this.count = rows.length
          page++
          await this.$nextTick()
        } while (response.length === limit)
        this.initiativesTax = _.uniqBy(rows, 'id')
        const info = await dbInitiativesTax.info()
        if (info.doc_count > 0) {
          await dbInitiativesTax.destroy()
          dbInitiativesTax = null
          dbInitiativesTax = new PouchDB('initiativesTax')
        }
        await dbInitiativesTax.bulkDocs(this.initiativesTax)
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    reload: async function () {
      this.loadingBackground = true
      try {
        await Promise.all([this.loadRows()])
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
      this.ready = false
      this.loadingBackground = false
      this.loading = false
      this.ready = true
    }
  }
}
</script>
<style scoped>
#top-menu-bar a:hover {
  font-weight: bold;
}
#top-menu-bar li a {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
}
#top-menu-bar li .active {
  background: #00B8F5;
  font-weight: bold;
}
</style>
